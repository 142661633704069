export default function ListingVeloNavShopify() {
    return (
        <div className="burgur-content-text-velo">
            <a
                href="https://levelomad.com/pages/elite-trekking"
                rel="noreferrer noopener"
                className="link-burger-velo"
            >
                <img
                    src={
                        "https://levelomad.com/cdn/shop/files/urban-elite-profil-noir.png?v=1707991275&width=100"
                    }
                    alt="Urban Elite"
                    className="img-burger-velo"
                />
                <div>
                    <h3>Urban Elite</h3>
                    <p>En ville ou hors zone urbaine, à vous de choisir.</p>
                </div>
            </a>
            <a
                href="https://levelomad.com/pages/velo-elite"
                rel="noreferrer noopener"
                className="link-burger-velo"
            >
                <img
                    src={
                        "https://levelomad.com/cdn/shop/files/sport-elite-profil.png?v=1687871895&width=250"
                    }
                    alt="Sport Elite"
                    className="img-burger-velo"
                />
                <div>
                    <h3>Sport Elite</h3>
                    <p>En ville ou hors zone urbaine, à vous de choisir.</p>
                </div>
            </a>
            <a
                href="https://levelomad.com/pages/velo-sport-plus"
                rel="noreferrer noopener"
                className="link-burger-velo"
            >
                <img
                    src={
                        "https://levelomad.com/cdn/shop/files/sport-plus-vert-profil.png?v=1687940312&width=250"
                    }
                    alt="Sport +"
                    className="img-burger-velo"
                />
                <div>
                    <h3>Sport +</h3>
                    <p>Pour une conduite nerveusse, définitivement plus Mad.</p>
                </div>
            </a>
            <a
                href="https://levelomad.com/pages/gamme-v2"
                rel="noreferrer noopener"
                className="link-burger-velo"
            >
                <img
                    src={
                        "https://levelomad.com/cdn/shop/products/VELOMAD-urbain-2-noir-profil-pneu-poignees-selle-noire.png?v=1692020620&width=250"
                    }
                    alt="Urban 2"
                    className="img-burger-velo"
                />
                <div>
                    <h3>Urban 2</h3>
                    <p>Ce qui a fait notre succès, en mieux.</p>
                </div>
            </a>
            <a
                href="https://levelomad.com/pages/gamme-v2"
                rel="noreferrer noopener"
                className="link-burger-velo"
            >
                <img
                    src={
                        "https://levelomad.com/cdn/shop/products/VELOMAD-urbain-2-noir-profil-pneu-poignees-selle-noire.png?v=1692020620&width=250"
                    }
                    alt="Sport 2"
                    className="img-burger-velo"
                />
                <div>
                    <h3>Sport 2</h3>
                    <p>Ce qui a fait notre succès, en mieux.</p>
                </div>
            </a>

            <a
                href="https://levelomad.com/pages/cargo-mad"
                rel="noreferrer noopener"
                className="link-burger-velo"
            >
                <img
                    src={
                        "https://levelomad.com/cdn/shop/files/GIFPRODUITCARGO.gif?v=1694445017&width=250"
                    }
                    alt="Cargo Mad"
                    className="img-burger-velo"
                />
                <div>
                    <h3>Cargo Mad</h3>
                    <p>Le Velomad Cargo Ultra-Compact.</p>
                </div>
            </a>
            <a
                href="https://levelomad.com/pages/cargo-veloe"
                rel="noreferrer noopener"
                className="link-burger-velo"
            >
                <img
                    src={
                        "https://levelomad.com/cdn/shop/files/multi-lungo-shimano-black.png?v=1725028124&width=100"
                    }
                    alt="Cargo Multi Lungo - Véloé"
                    className="img-burger-velo"
                />
                <div>
                    <h3>Cargo Multi Lungo - Véloé</h3>
                    <p>Notre petit dernier de notre gamme Cargo</p>
                </div>
            </a>
        </div>
    );
}
