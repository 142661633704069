import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    useLocation,
    Navigate,
} from "react-router-dom";
import Header from "../Components/Header/Header";
import Sav from "../Pages/Sav";
import Footer from "../Components/Footer/Footer";
import Login from "../Pages/Login";
import PageError from "../Pages/404";
import GestionBdd from "../Pages/GestionBdd";
import GestionHome from "../Pages/GestionHome";
import GestionSav from "../Pages/GestionSav";
import GestionUsers from "../Pages/GestionUsers";
import Logout from "../Pages/Logout";
import GestionStats from "../Pages/Stats";
import GestionUsersList from "../Pages/GestionUsersList";
import Maps from "../Pages/Maps";
import GestionMaps from "../Pages/GestionMaps";
import HeaderERP from "../Components/Header/HeaderERP";
import EtatStock from "../Components/Stats/EtatStock";
import EtatCommandes from "../Components/Stats/EtatCommandes";
import EtatPieces from "../Components/Stats/EtatPieces";
import EtatVelos from "../Components/Stats/EtatVelos";
import EtatPrecommandes from "../Components/Stats/EtatPrecommandes";
import EtatArticlesNonExpedies from "../Components/Stats/EtatArticlesNonExpedies";
import EtatReparations from "../Components/Stats/EtatReparation";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import GestionMapsStats from "../Pages/GestionMapsStats";
import HeaderShopifyVelomad from "../Components/shopifyVelomad/headerShopify";
import SavChoiceType from "../Pages/SavChoiceType";
import EtatDisponible from "../Components/Stats/EtatDisponible";
import GestionParrainage from "../Pages/GestionParrainage";
import PageReprise from "../Pages/Reprise";
import PageParrainage from "../Pages/Parrainage";
import TDBStats from "../Components/Stats/TDBStats";
import PageVentesPrivees2024 from "../Pages/ventespriveeete2024";
import GestionReprise from "../Components/Reprise/GestionReprise";

function ProtectedRoute({ children }) {
    const location = useLocation();
    const accessToken = useSelector((store) => store.user.accessToken);
    const authUser = useSelector((store) => store.user.authUser);
    if (authUser === undefined || authUser === null || accessToken === null) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/connexion" state={location.pathname} />;
    }

    return children;
}
function RedirectVelomadNewsletter() {
    useEffect(() => {
        window.location.replace(
            "https://levelomad.com#NewsletterForm--sections--19574676291865__footer"
        );
    }, []);
}
export default function Router() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route>
                    <Route index path="/" element={<Maps />}></Route>

                    <Route
                        path="/code-vp"
                        element={
                            <>
                                <HeaderShopifyVelomad />
                                <PageVentesPrivees2024 />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/demande-sav"
                        element={
                            <>
                                <HeaderShopifyVelomad />
                                <SavChoiceType />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/demande-sav-b2c"
                        element={
                            <>
                                <HeaderShopifyVelomad />
                                <Sav type="" />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/demande-sav-b2b"
                        element={
                            <>
                                <HeaderShopifyVelomad />
                                <Sav type="b2b" />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/demande-reprise/"
                        element={
                            <>
                                <HeaderShopifyVelomad />
                                <PageReprise type="reprise" />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/demande-parrainage/"
                        element={
                            <>
                                <HeaderShopifyVelomad />
                                <PageParrainage />
                            </>
                        }
                    ></Route>

                    <Route
                        path="/ambassadeurs"
                        element={
                            <>
                                <Maps
                                    filterCat={{ id: 2, name: "Ambassadeurs" }}
                                />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/reparateurs"
                        element={
                            <>
                                <Maps
                                    filterCat={{ id: 4, name: "Réparateurs" }}
                                />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/revendeurs"
                        element={
                            <>
                                <Maps
                                    filterCat={{ id: 3, name: "Revendeurs" }}
                                />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/magasins"
                        element={
                            <>
                                <Maps filterCat={{ id: 1, name: "Magasins" }} />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/tous-nos-partenaires"
                        element={
                            <>
                                <Maps filterCat={{ id: 0, name: "Tous" }} />
                            </>
                        }
                    ></Route>

                    <Route
                        path="/connexion"
                        element={
                            <>
                                <Login />
                                <Footer section={"Erp"} />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/deconnexion"
                        element={
                            <>
                                <Header />
                                <Logout />
                                <Footer section={"Erp"} />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/gestion"
                        element={
                            <ProtectedRoute>
                                <HeaderERP />
                                <GestionHome />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/reprise"
                        element={
                            <ProtectedRoute>
                                <HeaderERP />
                                <GestionReprise />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/bdd"
                        element={
                            <ProtectedRoute>
                                <HeaderERP />
                                <GestionBdd />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/shopify/customers"
                        element={
                            <ProtectedRoute>
                                <HeaderERP />
                                <GestionUsersList />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/shopify/parrainage"
                        element={
                            <ProtectedRoute>
                                <HeaderERP />
                                <GestionParrainage />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/sav"
                        element={
                            <ProtectedRoute>
                                <HeaderERP />
                                <GestionSav />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/stats"
                        element={
                            <ProtectedRoute>
                                <HeaderERP />
                                <GestionStats />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/stats/tdb"
                        element={
                            <ProtectedRoute>
                                <HeaderERP />
                                <TDBStats />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/stats/dispo"
                        element={
                            <ProtectedRoute>
                                <HeaderERP link={"dispo"} />
                                <EtatDisponible />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/stats/stock"
                        element={
                            <ProtectedRoute>
                                <HeaderERP link={"stock"} />
                                <EtatStock />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/stats/commandes"
                        element={
                            <ProtectedRoute>
                                <HeaderERP link={"commandes"} />
                                <EtatCommandes />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/stats/pieces"
                        element={
                            <ProtectedRoute>
                                <HeaderERP link={"pieces"} />
                                <EtatPieces />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/stats/reparations"
                        element={
                            <ProtectedRoute>
                                <HeaderERP link={"reparations"} />
                                <EtatReparations />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/stats/velos"
                        element={
                            <ProtectedRoute>
                                <HeaderERP link={"velos"} />
                                <EtatVelos />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/stats/precommandes"
                        element={
                            <ProtectedRoute>
                                <HeaderERP link={"precommandes"} />
                                <EtatPrecommandes />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/stats/nonlivres"
                        element={
                            <ProtectedRoute>
                                <HeaderERP link={"nonlivres"} />
                                <EtatArticlesNonExpedies />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/users"
                        element={
                            <ProtectedRoute>
                                <HeaderERP />
                                <GestionUsers />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/maps"
                        element={
                            <ProtectedRoute>
                                <HeaderERP />
                                <GestionMaps />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/maps/stats"
                        element={
                            <ProtectedRoute>
                                <HeaderERP />
                                <GestionMapsStats />
                                <Footer section={"Erp"} />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="*"
                        element={
                            <>
                                <HeaderShopifyVelomad />
                                <PageError />
                                <Footer />
                            </>
                        }
                        status={404}
                    ></Route>
                    <Route
                        path="/newsletter"
                        element={<RedirectVelomadNewsletter />}
                    />
                </Route>
            </>
        )
    );
    return router;
}
